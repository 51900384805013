import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';

function App() {
  useEffect(() => {
    window.location.href =
      'superpe://deep_link_value/=HomeTabs/SendScreen/PaySheet';
    if (isAndroid) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.mewt.app';
    } else if (isIOS) {
      window.location.href =
        'https://apps.apple.com/in/app/superpe/id6471577933';
    } else {
      window.location.href = 'https://superpe.in';
    }
  }, []);

  return <div className='App'></div>;
}

export default App;
